import React from 'react';
import { StructuredText as DatoStructuredText, renderMarkRule } from 'react-datocms';
import EmbeddedVideoPlayer from '@/components/EmbeddedVideoPlayer/EmbeddedVideoPlayer';
import { Image as DatoImage } from 'react-datocms';
import Image from 'next/image';
import { ImageRecord, FileFieldInterface } from '../../graphql/generated/datocms';
import { colors } from '../../plugin/structuredText/colors';
import MockLaptop from "@/components/MockLaptop/MockLaptop";

const StructuredText = ({ styleName = 'structured-text-block', className = '', content = null, data = null }) => {
    return (
        <div className={`${styleName} ${className}`}>
            <DatoStructuredText
                data={content ?? data}
                customMarkRules={[// convert "strong" marks into <b> tags
                    ...colors.map(([name, color]) => {
                        return renderMarkRule(`color-${name}`, ({ mark, children, key }) => <span key={key} style={{ color }}>{children}</span>);
                    })
                ]}
                renderInlineRecord={({ record }) => {
                    if (record.__typename === 'VideoBlockRecord') {
                        return (<span>VIDEO</span>);
                    } else {
                        return (<span>{record.__typename}</span>);
                    }
                }}
                // renderInlineRecord={({ record }) => {
                //     switch (record.__typename) {
                //         case "BlogPostRecord":
                //             return <a href={`/blog/${record.slug}`}>{record.title}</a>;
                //         default:
                //             return null;
                //     }
                // }}
                renderLinkToRecord={({ record, children }) => {
                    return <div>{children}</div>;
                    // switch (record.__typename) {
                    //     case "BlogPostRecord":
                    //         return <a href={`/blog/${record.slug}`}>{children}</a>;
                    //     default:
                    //         return null;
                    // }
                }}
                renderBlock={({ record }) => {
                    // console.log('record', record);
                    switch (record.__typename) {
                        case 'VideoVimeoRecord': {
                            return (
                                <EmbeddedVideoPlayer
                                    className={record.className}
                                    title={record.title}
                                    description={record.description}
                                    vimeoVideoId={record.vimeoVideoId}
                                    videoUrl={record.videoUrl}
                                    aspectRatio={9.0 / 16.0}
                                    autoplay={record.autoplay as boolean}
                                    controls={record.controls as boolean}
                                    muted={record.muted as boolean}
                                />
                            );
                        }
                        case 'ImageRecord': {
                            const image = record.image as FileFieldInterface;
                            return (
                                <div style={{ margin: 'auto', width: `${record.width ?? 100}%` }}>
                                    <DatoImage data={{ ...image.responsiveImage, bgColor: '#ffffff00' }} />
                                </div>
                            );
                            // return (<Image
                            //     src={image.url}
                            //     width={image.width}
                            //     height={image.height}
                            //     alt={image.alt}
                            //     loading="eager"
                            // />);
                        }
                        case 'ImageGalleryRecord': {
                            // const gallery = record as ImageGalleryRecord;
                            const images = record.images as Array<ImageRecord>;
                            return (
                                <div className='image-gallery'>
                                    {images.map(({ image }) => {
                                        return (
                                            <Image
                                                key={image.url}
                                                className='image-gallery-item'
                                                src={image.url}
                                                width={image.width}
                                                height={image.height}
                                                alt={image.alt}
                                                loading='eager'
                                            />
                                        );
                                    })}
                                </div>
                            );
                        }
                        case 'CustomBlockRecord': {
                            // get the name
                            const name = record.name as string;
                            if (name === 'MockLaptop') {
                                return (
                                    <MockLaptop className={record.class as string}/>
                                );
                            }
                            break;
                        }
                        // return <VimeoPlayer videoId={record.vimeoVideoId} />;
                        // return <img src={record.image.url} alt={record.image.alt} />;
                        default:
                            return null;
                    }
                }}
            />
        </div>
    );
};

export default StructuredText;
