import React from 'react';
import EquationsGif from '@/assets/gif/equations-muzology.gif';
// import LaptopImage from '@/assets/graphics/laptop.png';
import Image from 'next/image';
import LaptopImage from './Laptop.png';

const MockLaptop = ({ className = '', videoSrc = '/assets/video/platform-sample.mp4' }) => {
    return (
        <div className={`relative ${className}`} aria-hidden='true'>

            <Image src={LaptopImage} priority={true} alt='Macbook' />

            <div className=' ghost-inspector-hide absolute top-[7%] left-[16%] right-[16%] bottom-[14%] flex items-stretch justify-center bg-black px-1'>
                <video autoPlay muted loop playsInline width='100%' height='auto' className='relative '>
                    <source src={videoSrc} type='video/mp4' />
                </video>
            </div>
        </div>
    );
};

export default MockLaptop;
