import React from 'react';
import { RatingBar } from './RatingBar';
import { Image as DatoImage } from 'react-datocms';

interface Props {
    testimonial: any;
    className?: string;
}

const TestimonialCard = ({ testimonial, className }: Props) => {
    if (!testimonial) {
        return null;
    }
    console.log(testimonial);
    const { name, organization, image, rating, quote } = testimonial;
    console.log('image', image);
    return (
        <div className='card-shadow bg-white rounded-2xl shadow-sm py-5 px-[30px] h-auto w-full flex '>
            <div className='w-10 mt-4 flex flex-col justify-between'>
                <img style={{ width: '100%', height: 'auto' }} src='/assets/graphics/testimon-quote-mark.svg' alt='quote' aria-hidden='true' />
            </div>
            <div className='flex flex-1 flex-col justify-between pl-6 '>
                <h5 className=' my-3 text-[17px] leading-[20.57px] text-[#4F4F4F] font-normal text-left'>
                    {quote}
                </h5>

                <div className={'mt-2 flex items-center ' + className}>
                    <div className='w-[60px]'>
                        {/*
                        <img style={{ width: '100%', height: 'auto' }} src={image.url} />
                        <Image src={image.url} width={image.width} height={image.height} />
                        */}
                        {image && <DatoImage priority={false} data={{ ...image?.responsiveImage, bgColor: '#ffffff00' }} />}
                    </div>
                    {/*
                    <div className='hidden sm:block'>
                        <div className='w-[60px]'>
                            <img style={{ width: '100%', height: 'auto' }} src={image.url} />
                        </div>
                    </div>
                    */}
                    <div className='flex-1 pl-5 self-start'>
                        <div className='w-fit'>
                            <RatingBar rating={rating} size={20} />
                        </div>
                        <p className='text-sm sm:text-base lg:text-normal leading-[normal] font-bold mt-2 mb-1'>{name}</p>
                        <p className='text-sm sm:text-base lg:text-normal leading-[normal] font-light m-0 text-[#33333]'>{organization}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;
