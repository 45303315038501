import React, { useMemo } from 'react';
import { useMeasure } from 'react-use';

// use dynamic import to avoid SSR issues - react-player throws hydration errors when used in SSR
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

export const EmbeddedVideoPlayer = (
    {
        title = null,
        description = null,
        vimeoVideoId = null,
        videoUrl = null,
        videoId = null,
        aspectRatio = 9.0 / 16.0,
        className = null,
        muted = false,
        autoplay = false,
        controls = true
    }) => {

    // get the width and assume a 16:9 aspect ratio
    const [ref, { width }] = useMeasure();
    const height = useMemo(() => Math.min(width * aspectRatio, 400), [width, aspectRatio]);

    // construct the correct video url
    const url = useMemo(() => {
        if (!videoUrl && (vimeoVideoId || videoId)) {
            return `https://player.vimeo.com/video/${vimeoVideoId ?? videoId}`;
        }
        return videoUrl;
    }, [videoUrl, videoId, vimeoVideoId]);

    return (
        <div ref={ref} className={`EmbeddedVideoPlayer__wrapper ${className}`}>
            <ReactPlayer
                style={{
                    // Background: 'green',
                    // width: `${Math.max(width - 10, 800)}px`,
                    // height: `${height}px`,
                    marginBottom: '0px',
                    // marginLeft: 'auto',
                    // marginRight: 'auto',
                    // width: '40%'
                    // background: 'red',
                    width: '100%',
                    height: '100%',
                    // minHeight: height,
                    // minHeight: '480px',
                    // maxHeight: '100%',
                }}
                playing={autoplay}
                controls={controls}
                muted={muted}
                url={url}
                width={width}
                height={width * aspectRatio}
            />

            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}

            <style jsx>
                {`
                    .EmbeddedVideoPlayer__wrapper {
                        //background: red;

                        h2 {
                            font-family: Avenir, serif;
                            font-style: normal;
                            font-weight: 800;
                            font-size: 28px;
                            line-height: normal;
                            color: #000000;
                            //margin: 10px;
                            margin-bottom: 0;

                            @media (max-width: 400px) {
                                font-size: 28px;
                                line-height: normal;
                            }
                        }

                        p {
                            font-family: Avenir, serif;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 25px;
                            color: #4f4f4f;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default EmbeddedVideoPlayer;
