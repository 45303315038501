import React from 'react';

export const RatingBar = ({ rating, size = 16 }) => (
	<div className="rating-bar">
		<div className="rating-bar--content">
			{Array.from({ length: 5 }, (_, i) => (
				<div key={i} className={`rating-bar--star star-${i}`}>
					<img src={`/assets/graphics/rating-star-${i < rating ? 'color' : 'grey'}.svg`} alt={'star'} />
				</div>
			))}
		</div>
		<style jsx>
			{`
          .rating-bar {
            width: 100%;

            .rating-bar--content {
              width: 100%;
              display: flex;

              .rating-bar--star {
                width: ${size}px;
                height: ${size}px;
                margin: 0;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            @media (max-width: 992px) {
            }
            @media (max-width: 480px) {
            }
          }
        `}
		</style>
	</div>
);
