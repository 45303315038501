import React from 'react';
import { RatingBar } from './RatingBar';
import { Image as DatoImage } from 'react-datocms';

interface Props {
    testimonial: any;
    className?: string;
}

const TestimonialCardNew = ({ testimonial, className }: Props) => {
    if (!testimonial) {
        return null;
    }

    const { name, organization, rating, quote } = testimonial;
    return (
        <div
            className='card-shadow bg-white rounded-2xl shadow-sm py-5 px-5 h-auto w-full flex '
            style={{ boxShadow: '0px 12px 24px 0px rgba(20, 20, 43, 0.13), 0px -2px 4px 0px rgba(20, 20, 43, 0.02), 0px 3px 14px 0px rgba(74, 58, 255, 0.03)' }}
        >
            <div className='w-10 mt-4 flex flex-col justify-between'>
                <img style={{ width: '100%', height: 'auto' }} src='/assets/graphics/testimon-quote-mark-blue.svg' alt='quote' aria-hidden='true' />
            </div>
            <div className='flex flex-1 flex-col justify-between pl-6 '>
                <h5 className=' my-3 text-base sm:text-[19px] leading-[normal] text-[#1C0057] font-normal text-left max-w-[360px]'>{quote}</h5>

                <div className={'mt-2 flex items-center ' + className}>
                    <div className='flex-1 self-start'>
                        <div className='text-sm sm:text-base lg:text-normal leading-[normal] font-semibold mt-2 text-[#1C0057]'>{name}</div>
                        <div className='text-sm sm:text-base lg:text-normal leading-[normal] font-semibold m-0 text-[#1C0057]'>{organization}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCardNew;
