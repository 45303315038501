import React from 'react';
import TestimonialCard from './TestimonialCard';
import TestimonialCardNew from './TestimonialCardNew';

import { Heading2 } from '@/components/Views/Heading';

interface Props {
    testimonials?: any;
    heading?: string;
    className?: string;
    newHomepage?: boolean;
}

const TestimonialGrid = ({ testimonials, heading = null, className = '', newHomepage }: Props) => {
    const slicedTestimonials = testimonials?.slice(0, newHomepage ? 4 : 6) ?? [];
    return (
        <section id='testimonial-grid' className={`default-py ${className}`}>
            <div className='default-max-w m-auto font-Inter relative pb-10 px-8 bg-center bg-no-repeat bg-cover'>
                {!newHomepage && <Heading2 className='text-center md:text-center mt-10' heading={heading ?? 'Hear from Some of our Fans!'} />}
                {newHomepage && <Heading2 className='text-center md:text-center mt-10 mx-auto max-w-[520px] md:max-w-[600px] lg:max-w-[670px] xl:max-w-[740px] font-Poppins font-semibold' heading={heading} />}

                <div className='mt-6 md:mt-10 lg:mt-16 mx-auto max-w-[1000px]'>
                    <div className='hidden lg:grid grid-cols-1 lg:grid-cols-2 auto-rows-auto auto-rows-max gap-8 justify-items-center justify-center'>
                        {slicedTestimonials.map((testimonials, index) => {
                            return newHomepage ? <TestimonialCardNew testimonial={testimonials} key={index} className='min-h-[80px]' /> : <TestimonialCard testimonial={testimonials} key={index} className='min-h-[80px]' />;
                        })}
                    </div>
                    <div className='grid lg:hidden grid-cols-1 lg:grid-cols-2 auto-rows-auto auto-rows-max gap-8 justify-items-center justify-center'>
                        {slicedTestimonials?.slice(0, newHomepage ? 4 : 3).map((testimonials, index) => {
                            return newHomepage ? <TestimonialCardNew testimonial={testimonials} key={index} /> : <TestimonialCard testimonial={testimonials} key={index} />;
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialGrid;
