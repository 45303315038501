export const colors = [
    ['Red', '#CD0D0D'],
    ['Orange', '#ffaa00'],
    ['Yellow', '#fff407'],
    ['Green', '#33cf13'],
    ['Blue', '#0e37e1']
];

export const colorMarks = colors.map(([name, color]) => ({
    id: 'color-' + name,
    label: name,
    icon: {
        type: 'svg',
        viewBox: '0 0 8 9',
        content: `<path d='M1.41477 9H0.306818L3.51136 0.272727H4.60227L7.80682 9H6.69886L4.09091 1.65341H4.02273L1.41477 9ZM1.82386 5.59091H6.28977V6.52841H1.82386V5.59091Z' fill='${color}'/>`
    },
    appliedStyle: { color }
}));
